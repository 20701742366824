import * as React from "react";
import { Component } from "react";
import * as PropTypes from "prop-types";
import { Route, Link } from "react-router-dom";
import gql from "graphql-tag";
import { graphql } from "react-apollo";
import ApolloClient from "apollo-client";

// @ts-ignore
import styled from "styled-components";

type Props = {
  className?: string;
  style?: React.CSSProperties;
};

export default class Settings extends React.Component<Props> {
  static contextTypes = {
    translator: PropTypes.func,
    client: PropTypes.instanceOf(ApolloClient),
    router: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
  };

  render() {
    const { router, client, translator, account } = this.context;

    return (
      <div
        className={this.props.className}
        style={{ ...this.props.style, color: "#ffffff" }}
      >
        <div className="section">
          <a>{translator("Email")}</a>
          <a href="/settings/senders" className="child">
            {translator("Senders")}
          </a>
          <a href="/settings/reviewers" className="child">
            {translator("Reviewers")}
          </a>
        </div>

        <div className="seperator" />
        <div className="section">
          <a>{translator("Users")}</a>
          <a href="/settings/users_list" className="child">
            {translator("Users")}
          </a>
          <a href="/settings/usergroups_list" className="child">
            {translator("User groups")}
          </a>
        </div>

        {/* {(account->type === 'Ultimate')&&
					<div className="seperator"></div>
					<div className="section">
						<a>{translator("Job Settings")}</a>
						<a href="/settings/call_responses_list" className="child">{translator("Responses")}</a>
						<a href="/settings/call_denies" className="child">{translator("Refusal Codes")}</a>
						<a href="/settings/lead_origins" className="child">{translator("Origins")}</a>
						<a href="/settings/politic_settings" className="child">{translator("Politic settings")}</a>
						{if $clientSettings->get('filter_whale') == 'on'}
							<a href="/settings/filters_list" className="child">{translator("Saved Filters")}</a>
						{/if}
					</div>
				} */}

        <div className="seperator" />
        <div className="section">
          <a>{translator("Recipients Settings")}</a>
          <a href="/settings/position" className="child">
            {translator("Positions")}
          </a>
        </div>

        {/* {account->ja_access_code &&(
					<div className="seperator"></div>
					<div className="section">
						<a>{translator("Ja.is gateway")}</a>
						<a href="/settings/ja_settings" className="child">{translator("Settings")}</a>
						<a href="/settings/update_from_ja" className="child">{translator("Update a list from Ja.is")}</a>
					</div>)
				} */}

        <div className="seperator" />
        <div className="section">
          <a>{translator("System Settings")}</a>
          <a href="/settings/general_settings" className="child">
            {translator("General Settings")}
          </a>
          <a href="/settings/idea_groups" className="child">
            {translator("Idea Groups")}
          </a>
          <a href="/settings/lists" className="child">
            {translator("Lists")}
          </a>
          <a href="/settings/tools" className="child">
            {translator("Tools")}
          </a>
          <a href="/settings/audience_toggler" className="child">
            {translator("Audience toggler")}
          </a>
        </div>

        <div className="seperator" />
        <div className="section">
          <a>{translator("Toolbox")}</a>
          <a href="/settings/notes" className="child">
            {translator("Notes")}
          </a>
        </div>
      </div>
    );
  }
}
