/* eslint import/first: 0 */
/* eslint import/no-extraneous-dependencies: 0 */
/* eslint import/no-unresolved: 0 */
import * as React from "react";
import { render } from "react-dom";
import App from "./components/App";
import LeftMenu from "./components/LeftMenu";
import BaseApp from "./init/BaseApp";

window.onload = () => {
  const rootElement = document.getElementById("root");

  const Root = () => (
    <BaseApp>
      <App />
    </BaseApp>
  );

  if (rootElement) {
    render(<Root />, rootElement);
  }

  const LeftMenuItem = () => (
    <BaseApp>
      <LeftMenu />
    </BaseApp>
  );

  const leftMenuElement = document.getElementById("LeftMenu");
  if (leftMenuElement !== null) {
    render(<LeftMenuItem />, leftMenuElement);
  }
};

console && console.log && console.log("Zenter system " + process.env.VERSION);
